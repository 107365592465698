import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { AIConversation } from '../../extViewmodels/sdk/models/AI';
import { useUserSession } from '../../models/hooks/appStateHooks';

export function useAIAssistantCreateConversationMutation({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: AIConversation) => void;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: (params: {
			assistantName: string;
			impersonationContext?: Api.IImpersonationContext;
		}) =>
			userSession.webServiceHelper.callAsync<AIConversation>(
				Api.ImpersonationBroker.composeApiUrl({
					urlPath: `ai/assistant/${params.assistantName}/conversation`,
					impersonationContext: params.impersonationContext,
				}),
				'POST'
			),
		onError,
		onSuccess,
	});
}
