import { css, StyleDeclaration } from 'aphrodite';
import { bs } from '../../../../styles/styles';

export function SparklesIcon({
	styles = [],
	...props
}: Exclude<React.SVGProps<SVGSVGElement>, 'className'> & { styles?: StyleDeclaration[] }) {
	return (
		<svg width={20} height={20} viewBox='0 0 17 22' className={css(bs.textBrandPrimary, ...styles)} {...props}>
			<path
				fill='currentColor'
				d='M7.5 3c0 5.247 3.358 9.5 7.5 9.5-4.142 0-7.5 4.253-7.5 9.5 0-5.247-3.358-9.5-7.5-9.5 4.142 0 7.5-4.253 7.5-9.5M13.5 0c0 2.485 1.567 4.5 3.5 4.5-1.933 0-3.5 2.015-3.5 4.5 0-2.485-1.567-4.5-3.5-4.5 1.933 0 3.5-2.015 3.5-4.5'
				fillRule='evenodd'
			/>
		</svg>
	);
}
